import { combineReducers } from "redux";
import reducerOne from './reducerone'

const livingBeautyReducers = combineReducers({
    reducerOne
});
export default livingBeautyReducers

// export default combineReducers(
//   Object.assign({})
// );
